/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'

/* Vue auth */
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

/* Vue-Tel-Input */
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

/* Vue-Debounce */
import vueDebounce from 'vue-debounce'

/* Moment JS */
import moment from 'moment'

import './mixins/mixin'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('Apexchart', VueApexCharts)

Vue.filter('formatDate', function (value, isDate) {
  if (value) {
    return moment(value).format(isDate ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')
  }
})

Vue.filter('formatDateLocale', function (value) {
  var mm = require('moment-timezone')
  // console.log(mm.tz.guess(true))
  // return moment.tz.guess()
  // return localDate.format('MMMM Do YYYY, h:mm a')
  return mm(value).tz(mm.tz.guess()).format('MMMM Do YYYY, h:mm a')
})

Vue.use(vueDebounce)

/* Fetch sample data */
store.dispatch('fetch', 'clients')

/* Default title tag */
const defaultDocumentTitle = 'LincTech Dashboard'

Vue.use(VueAxios, axios)
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
axios.defaults.baseURL = 'https://linctechapp.com/api'

Vue.router = router
Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    // http: Vue.http, // Vue Resource
    router: Vue.router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    notFoundRedirect: { name: 'user-account' },
    loginData: { url: '/v1/auth/login', method: 'POST', redirect: '/' },
    fetchData: { url: '/v1/auth/user', method: 'GET', enabled: true },
    refreshData: { url: '/v1/auth/refresh', method: 'GET', enabled: true },
    logoutData: { url: '/v1/auth/logout', method: 'GET', redirect: '/login' },
    rolesKey: 'role',
    parseUserData: (data) => {
      return data
    }
  }
})

/* Vue-Tel-Input */
Vue.use(VueTelInput)

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
